export const defaultFiltersObject = () => ({
  shops: [],
  channels: [],
  statuses: [],
  reservedAt: [],
  pickUpAt: [],
  dropOffAt: [],
  updatedAt: [],
  searchValue: ""
})
