import { map } from "lodash-es"
import { mappedDateRange } from "@/config/date_helpers"

import StoreItemsModule from "@/config/store/StoreItemsModule"
import { withLoading } from "@/config/store/StoreItemsModule/helpers"

import { defaultFiltersObject } from "./helpers.js"
import { extractCommonMutations, buildCommonState } from "../shared"
import extractActions from "./actions"

const mapFilters = filters => {
  return {
    shop_ids: map(filters.shops, "id"),
    channels: map(filters.channels, "id"),
    statuses: map(filters.statuses, "id"),
    reserved_at: mappedDateRange(filters.reservedAt),
    pick_up_at: mappedDateRange(filters.pickUpAt),
    drop_off_at: mappedDateRange(filters.dropOffAt),
    updated_at: mappedDateRange(filters.updatedAt),
    search_value: filters.searchValue
  }
}

const baseURI = "/companies/reservations"

const store = new StoreItemsModule({
  baseURI: baseURI,
  presetActions: ["fetch", "delete"],
  withFilters: defaultFiltersObject(),
  withSorting: {
    field: "id",
    direction: "desc"
  },
  withPagination: true,
  mapFilters
})

store.mergeState(buildCommonState())

store.mergeMutations(extractCommonMutations())
store.mergeActions(extractActions({ baseURI }), withLoading)

export default store
